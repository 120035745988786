import Vue from 'vue'
import Vuex from 'vuex'
import User from './modules/user'
import General from './modules/general'
import Wallet from "@/store/modules/wallet";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: User,
    general: General,
    wallet: Wallet
  }
})
