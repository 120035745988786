<template>
  <span class="preloader">
    <span class="image"><img src="@/assets/img/preloader.svg" alt="" /></span>
    <span class="title">{{ "Loading" }}</span>
  </span>
</template>

<script>
export default {
  name: "Preloader",
};
</script>

<style scoped>

.title {
  color: #fff;
}

.preloader {
  display: flex;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  flex-direction: column;
  justify-content: center;
  align-items: center;

  background-color: #111;

  z-index: 9999;

  user-select: none;
}

.preloader .image {
  width: 200px;
  height: 200px;
}

.preloader .image img {
  width: 100%;
  height: 100%;
}

.preloader .title:not(:empty) {
  margin-top: -30px;
}
</style>
