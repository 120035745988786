<template>
  <div class="checkbox">
    <label class="container">
      <input
        class="container-checkbox"
        type="checkbox"
        :value="label"
        @change="checkboxClick"
        v-model="isChecked"
      />
      <span></span>
      <span class="container-checkmark">{{ label }}</span>
    </label>
  </div>
  <!-- <v-checkbox v-model="isChecked" :label="label" @change="checkboxClick">
  </v-checkbox> -->
</template>

<script>
export default {
  name: "FilterCheckbox",
  props: ["value", "data", "label"],
  emit: ["change"],
  data: () => ({
    isChecked: false,
  }),
  methods: {
    checkboxClick() {
      this.$emit("change", {
        value: this.value,
        add: this.isChecked,
      });
    },
  },
  mounted() {
    this.isChecked = this.data === this.value;
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  margin: 10px;
}
.container {
  &-checkbox {
    height: 15px;
    width: 15px;
    margin-right: 5px;
    cursor: pointer;
  }
}
</style>
