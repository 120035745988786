<template>
  <div>
    <v-tabs @change="toggleTab" color="pink">
      <v-tab>ACTIVE</v-tab>
      <v-tab>COMPLETE</v-tab>
    </v-tabs>
    <v-data-table
      :headers="headers"
      :items="reports"
      item-key="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="size"
    >
      <template v-slot:item.created="{ item }">
        {{ item.created | timePipe }}
      </template>
      <template v-slot:item.creator="{ item }">
        <div>
          Profile
          <a
            :href="`https://irnft.co/profile/${item.user.id}/bio`"
            target="_blank"
            >{{ item.user.id | cutHash }}</a
          >
        </div>
        <div>
          Email
          <a :href="`mailto:${item.replyMail}`" target="_blank">{{
            item.replyMail
          }}</a>
        </div>
      </template>
      <template v-slot:item.reportedUser="{ item }">
        <div>
          Profile
          <a
            :href="`https://irnft.co/profile/${item.creator.id}/bio`"
            target="_blank"
            >{{ item.creator.id | cutHash }}</a
          >
        </div>
      </template>
      <template v-slot:item.answerReport="{ item }">
        <AnswerReport
          :report-id="item.id"
          @success="getAllReportRequests(tab)"
        />
      </template>
    </v-data-table>
    <div class="text-center pa-5">
      <v-pagination
        v-if="total > 1"
        v-model="page"
        :length="total"
        :total-visible="10"
        circle
        color="primary"
        @input="getAllReportRequests"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import { toTimeMixin } from "@/utils/mixin";
import cutHashMixin from "@/mixins/cutHashMixin";
import AnswerReport from "@/components/reports/AnswerReport";

export default {
  name: "Reports",
  components: { AnswerReport },
  mixins: [toTimeMixin, cutHashMixin],
  data: () => ({
    totalElements: 0,
    reports: [],
    loading: false,
    page: 1,
    size: 20,
    total: 0,
    tab: 0,
  }),
  computed: {
    headers() {
      return [
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Creator",
          value: "creator",
        },
        {
          text: "Reported user",
          value: "reportedUser",
        },
        {
          text: "Type",
          value: "type",
        },
        {
          text: "Description",
          value: "description",
        },
        {
          text: "Answer",
          value: "answerReport",
        },
      ];
    },
  },
  methods: {
    async toggleTab(tab) {
      this.tab = tab;
      switch (this.tab) {
        case 0:
          await this.getAllReportRequests("ACTIVE");
          break;
        case 1:
          await this.getAllReportRequests("COMPLETE");
          break;
      }
    },
    async getAllReportRequests(status) {
      try {
        this.loading = true;
        const response = await api.getAllReportRequests({
          page: this.page - 1,
          size: this.size,
          reportRequestStatus: status,
          sort: "created,desc",
        });
        this.reports = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getAllReportRequests("ACTIVE");
  },
};
</script>
