import Store from '@/store'

function AuthGuard(to, from, next) {
    if(Store.getters['user/getIsAuthorized']) {
        next({
            path: '/'
        })
    }
    else {
        next()
    }
}

export default AuthGuard
