export default {
    namespaced: true,
    state: {
        drawer: !!localStorage.getItem('drawer'),
        error: null,
        success: null,
        loading: false,
    },
    mutations: {
        setError(state, payload) {
            state.error = payload;
        },
        setLoading(state, payload) {
            state.loading = payload;
        },
        setSuccess(state, payload) {
            state.success = payload;
        },
        setDrawer(state, payload) {
            if(payload === undefined) {
                if(state.drawer) {
                    localStorage.removeItem('drawer');
                } else {
                    localStorage.setItem('drawer', 'true');
                }
                state.drawer = !state.drawer;
            } else {
                if(!payload) {
                    localStorage.removeItem('drawer');
                } else {
                    localStorage.setItem('drawer', 'true');
                }
                state.drawer = !payload;
            }
        }
    },
    getters: {
        getDrawer: state => state.drawer,
        getSuccess: (state) => state.success,
        getError: (state) => state.error,
        getLoading: (state) => state.loading,
    }
}
