import {mapMutations} from "vuex";

export default {
    methods: {
        ...mapMutations({
            setSuccess: 'general/setSuccess',
            setLoading: 'general/setLoading',
            setError: 'general/setError',
        })
    },
}