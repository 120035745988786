import Store from '@/store'

const notAuthorizedGuard =  (to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if(!Store.getters['user/getIsAuthorized']) {
            next({
                path: '/sign-in'
            })
        }
        else {
            next()
        }
    }
    else {
        next();
    }
}

export default notAuthorizedGuard
