import Home from "../views/Home";
import SignIn from "../views/pages/SignIn";
import AuthGuard from "./guards/AuthGuard";
import Tokens from "@/views/Tokens";
import Collections from "@/views/Collections";
import Settings from "@/views/Settings";
import Tickets from "@/views/Tickets";
import Users from "@/views/Users";
import SliderConfig from "../views/SliderConfig";

export default [
    {
        path: '/',
        name: 'Home',
        component: Home,
        redirect: '/main-page-settings',
        meta: {
            requiresAuth: true
        },
        children: [
            {
                name: 'Main page settings',
                path: '/main-page-settings',
                component: SliderConfig,
            },
            {
                name: 'Tokens',
                path: '/tokens',
                component: Tokens,
            },
            {
                name: 'Collections',
                path: '/collections',
                component: Collections,
            },
            {
                name: "Tickets",
                path: "/tickets",
                component: Tickets,
            },
            {
                name: 'Settings',
                path: '/settings',
                component: Settings,
            },
            {
                name: 'Users',
                path: '/users',
                component: Users,
            }
        ]
    },
    {
        path: '/sign-in',
        name: 'SignIn',
        component: SignIn,
        beforeEnter: AuthGuard
    }
]
