<template>
  <v-container fluid>
    <AppBar/>
    <Drawer/>
    <v-main>
      <router-view/>
    </v-main>
    <AppFooter/>
  </v-container>
</template>

<script>
import AppBar from "../components/containers/AppBar";
import Drawer from "../components/containers/Drawer";
import AppFooter from "../components/containers/AppFooter";
export default {
  name: 'Home',
  components: {AppFooter, Drawer, AppBar}
}
</script>