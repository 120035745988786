export const toTimeMixin = {
  filters: {
    timePipe(timestamp) {
      const date = new Date(timestamp);
      let month = date.getMonth() + 1;
      let day = date.getDate();
      let year = date.getFullYear();
      let hours = date.getHours();
      let minutes = date.getMinutes();

      month < 10 ? (month = "0" + month) : "";
      day < 10 ? (day = "0" + day) : "";
      hours < 10 ? (hours = "0" + hours) : "";
      minutes < 10 ? (minutes = "0" + minutes) : "";
      // const output = month  + '\n'+ day  + ',' + year;
      return `${day}/${month}/${year} ${hours}:${minutes}`;
    },
  },
};

export const snakeCaseNormalizer = {
  filters: {
    stringPipe(string) {
      if (!string) return '';
      const normalized = string.toLowerCase().split("_").join(" ");
      return normalized[0].toUpperCase() + normalized.slice(1);
    },
  },
};
