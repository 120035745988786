import api from "@/api/api";
import setProcessingMixin from "@/mixins/setProcessingMixin";

export default {
    mixins: [setProcessingMixin],
    methods: {
        async createAdminObjectObject(payload) {
            try {
                await api.createAdminObject(payload);
                this.setSuccess("Object has been appointed");
            } catch (e) {
                this.setError(e.response.data.message);
            } finally {
                this.$router.go(-1);
            }
        }
    }
}