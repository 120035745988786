<template>
  <v-dialog
      transition="dialog-bottom-transition"
      max-width="600"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
          color="pink"
          dark
          v-bind="attrs"
          v-on="on"
      >Answer</v-btn>
    </template>
    <template v-slot:default="dialog">
      <v-card>
        <v-toolbar
            color="pink"
            dark
        >Answer report</v-toolbar>
        <v-card-text>
          <v-textarea
              name="input-7-1"
              label="Type your answer here"
              v-model="answer"
          ></v-textarea>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn
              text
              @click="() => {
                dialog.value = false
              }"
          >Save</v-btn>
          <v-btn
              text
              @click="() => {
                answerReport()
                  .then(() => {
                    dialog.value = false
                  })
              }"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>

</template>

<script>
import api from "@/api/api";
import setProcessingMixin from "@/mixins/setProcessingMixin";

export default {
  name: "AnswerReport",
  mixins: [setProcessingMixin],
  props: {
    reportId: {
      type: String,
      required: true,
    }
  },
  data: () => ({
    answer: null,
  }),
  methods: {
    async answerReport() {
      try {
        this.setLoading(true);
        await api.answerReport({
          reportId: this.reportId,
          message: this.answer,
        });
        this.setLoading(false);
        this.$emit("success");
        this.setSuccess("Answer submitted");

      } catch (e) {
        console.log(e);
        this.setLoading(false);
        this.setError(e.response.data.message);
      }
    }
  }
}
</script>
