import abi from "@/utils/abi";
import store from "@/store";
let methods = null;
let account = null;

const formatPrice = (price) => {
    let coefficient = store.getters["wallet/getMultiplier"];
    return Math.round(price * coefficient).toLocaleString("fullwide", {
        useGrouping: false,
    });
};

export default {
    initWeb3Methods(payload) {
        methods = new window.web3.eth.Contract(
            abi,
            store.getters["wallet/getContract"]
        ).methods;
        account = payload;
    },

    rateStepPercent() {
        return new Promise((resolve, reject) => {
            methods.rateStepPercent().call(
                {
                    from: account,
                },
                (err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res / Math.pow(10, 5));
                }
            );
        });
    },

    getAdmins() {
        return new Promise((resolve, reject) => {
            methods.getAdmins().call(
                {
                    from: account,
                },
                (err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res);
                }
            );
        });
    },

    maxRoyaltyPercent() {
        return new Promise((resolve, reject) => {
            methods.maxRoyaltyPercent().call(
                {
                    from: account,
                },
                (err, res) => {
                    if (err) {
                        reject(err);
                    }
                    resolve(res / Math.pow(10, 5));
                }
            );
        });
    },

    _addToAdminPool(address) {
        return new Promise((resolve, reject) => {
            methods
                ._addToAdminPool(address)
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _removeFromAdminPool(address) {
        return new Promise((resolve, reject) => {
            methods
                ._removeFromAdminPool(address)
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _setRateStepPercent(percent) {
        return new Promise((resolve, reject) => {
            methods
                ._setRateStepPercent(percent * Math.pow(10, 5))
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _setMinBidAmount(minBidAmount) {
        return new Promise((resolve, reject) => {
            methods
                ._setMinBidAmount(formatPrice(minBidAmount))
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _setMaxRoyaltyPercent(royaltyPercent) {
        return new Promise((resolve, reject) => {
            methods
                ._setMaxRoyaltyPercent(royaltyPercent * Math.pow(10, 5))
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _setSystemFeePercent(systemFeePercent) {
        return new Promise((resolve, reject) => {
            methods
                ._setSystemFeePercent(systemFeePercent)
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    _setSystemAddress(address) {
        return new Promise((resolve, reject) => {
            methods
                ._setSystemAddress(address)
                .send(
                    {
                        from: account,
                    },
                    (err) => {
                        if (err) {
                            reject(err);
                        }
                    }
                )
                .once("confirmation", (confirmationNumber, receipt) => {
                    if (receipt.status === true) {
                        resolve();
                    } else {
                        reject();
                    }
                });
        });
    },

    sign (address, dataToSign) {
        return new Promise((resolve, reject) => {
            window.web3.eth.personal
                .sign(
                    dataToSign,
                    address,
                    "",
                    (error, response) => {
                        console.log(error, response);
                    }
                )
                .then((res) => {
                    resolve({
                        address,
                        signature: res,
                    });
                })
                .catch((e) => {
                    reject(e.message);
                });
        });
    }
}
