<template>
  <v-select
    @change="toggleRole"
    style="width: 130px"
    :items="roles"
    label="Role"
    dense
    hide-details
    solo
    v-model="currentRole"
  ></v-select>
</template>

<script>
export default {
  name: "ToggleRole",
  props: {
    roles: {
      type: Array,
      required: true,
    },
    role: {
      type: String,
      required: true,
    },
    userId: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    items: [],
    currentRole: null,
  }),
  methods: {
    toggleRole(role) {
      this.$emit("toggleRole", {
        role,
        userId: this.userId,
      });
    },
  },
  mounted() {
    this.currentRole = this.roles.find((i) => i === this.role);
  },
};
</script>
