export default {
  methods: {
    appointAvatar(avatar) {
      if (avatar) {
        return avatar;
      }
      const DEFAULT_AVATAR_API = "https://avatars.dicebear.com/api/identicon/";
      const DEFAULT_AVATAR_EXTENSION = ".svg";
      return `${DEFAULT_AVATAR_API}${Math.floor(
        Math.random() * 100
      )}${DEFAULT_AVATAR_EXTENSION}`;
    },
  },
};
