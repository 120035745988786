<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="450"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon v-on="on">
        <v-icon>mdi-information-outline</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-simple-table light>
        <template>
          <tbody>
            <tr>
              <td>
                <h2>
                  {{ selectedItem.title }}
                </h2>
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>

            <tr>
              <td>
                <strong> Currency: </strong>
              </td>
              <td>
                {{
                  selectedItem.activeAuction.currency[0] +
                  selectedItem.activeAuction.currency.slice(1).toLowerCase()
                }}
              </td>
              <td>
                <strong> Status: </strong>
              </td>
              <td>
                {{
                  selectedItem.activeAuction.status[0] +
                  selectedItem.activeAuction.status.slice(1).toLowerCase()
                }}
              </td>
            </tr>
            <tr>
              <td>
                <strong> USD value: </strong>
              </td>
              <td>
                {{ selectedItem.activeAuction.currentUsdtPrice }}
              </td>
              <td>
                <strong> Min bid: </strong>
              </td>
              <td>
                {{ selectedItem.activeAuction.minBidPrice }}
              </td>
            </tr>
            <tr
              v-if="
                selectedItem.activeAuction.startTime !== null ||
                selectedItem.activeAuction.endTime !== null
              "
            >
              <td v-if="selectedItem.activeAuction.startTime !== null">
                <strong> Auction start: </strong>
              </td>
              <td v-if="selectedItem.activeAuction.startTime !== null">
                {{ +(selectedItem.activeAuction.startTime + "000") | timePipe }}
              </td>
              <td v-if="selectedItem.activeAuction.endTime !== null">
                <strong> Auction end: </strong>
              </td>
              <td v-if="selectedItem.activeAuction.endTime !== null">
                {{ +(selectedItem.activeAuction.endTime + "000") | timePipe }}
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-menu>
</template>

<script>
import { toTimeMixin } from "@/utils/mixin";

export default {
  name: "OverlayContent",
  props: ["selectedItem"],
  mixins: [toTimeMixin],
  data: () => ({
    menu: false,
  }),
  methods: {
    log() {
      console.log(this.selectedItem);
    },
  },
};
</script>
