<template>
  <v-container>
    <SliderConfigSelectType
      v-if="selectTypeModalVisible"
      :position="position"
      :object-id="objectId"
      @remove="deleteAdminObject"
      @close="selectTypeModalVisible = false"
    />
    <v-row class="mb-5">
      <v-col cols="2">
        <v-card
          v-for="(item, index) in slides.slice(0, 1)"
          :key="index"
          height="125"
          width="125"
          elevation="4"
          @click="showSelectTypeModal(1, item.id)"
        >
          <ResourceType
            v-if="item && item.token"
            :file-url="item.token.fileUrl"
            :file-type="item.token.type"
          />
          <img
            v-if="item && item.collection"
            style="height: 100%; width: 100%"
            :src="item.collection.fileUrl"
            alt="image"
          />
        </v-card>
      </v-col>
      <v-col cols="2">
        <v-row>
          <v-col
            :cols="6"
            v-for="(item, index) in slides.slice(1)"
            :key="index"
          >
            <v-card
              height="50"
              width="50"
              elevation="4"
              @click="showSelectTypeModal(1, item.id)"
            >
              <ResourceType
                v-if="item && item.token"
                :file-url="item.token.fileUrl"
                :file-type="item.token.type"
              />
              <img
                v-if="item && item.collection"
                style="height: 100%; width: 100%"
                :src="item.collection.fileUrl"
                alt="image"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8">
        <v-row>
          <v-col cols="3">
            <p>Collections display</p>
            <v-select
              :item-color="'pink'"
              :items="collectionsConfigs"
              :item-text="'text'"
              :item-value="'value'"
              :label="currentCollectionsConfig.text"
              @change="updateMainPageConfigs($event, 'collections')"
              solo
            ></v-select>
          </v-col>
          <v-col cols="3">
            <p>Creators display</p>
            <v-select
              :items="creatorsConfigs"
              :item-text="'text'"
              :item-value="'value'"
              :label="currentCreatorsConfig.text"
              @change="updateMainPageConfigs($event, 'creators')"
              solo
            ></v-select>
          </v-col>
          <v-col cols="3">
            <p>Live auctions display</p>
            <v-select
              :items="liveAuctionsConfigs"
              :item-text="'text'"
              :item-value="'value'"
              @change="updateMainPageConfigs($event, 'live')"
              :label="currentLiveAuctionsConfig.text"
              solo
            ></v-select>
          </v-col>
          <v-col cols="3">
            <p>NFT tokens display</p>
            <v-select
              :items="nftTokensConfigs"
              :label="currentNftTokensConfig.text"
              @change="updateMainPageConfigs($event, 'tokens')"
              solo
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-tabs @change="toggleTab" color="pink">
      <v-tab>LIVE AUCTIONS</v-tab>
      <v-tab>CREATORS</v-tab>
      <v-tab>COLLECTIONS</v-tab>
      <v-tab>TOKENS</v-tab>
    </v-tabs>
    <v-data-table
      :headers="appointHeaders"
      :items="items"
      item-key="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="size"
    >
      <template v-slot:item.nftToken.fileUrl="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="item.nftToken.fileUrl"
        />
      </template>
      <template v-slot:item.collection.fileUrl="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="item.collection.fileUrl"
        />
      </template>
      <template v-slot:item.collection.created="{ item }">
        {{ item.collection.created | timePipe }}
      </template>
      <template v-slot:item.nftToken.created="{ item }">
        {{ item.nftToken.created | timePipe }}
      </template>
      <template v-slot:item.user.avatar="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="appointAvatar(item.user.userInfo.avatarUrl)"
        />
      </template>
      <template v-slot:item.user.banner="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="appointAvatar(item.user.userInfo.bannerUrl)"
        />
      </template>
      <template v-slot:item.user.personalData="{ item }">
        <div>
          Nickname
          <a
            :href="`https://irnft.co/profile/${item.user.id}/bio`"
            target="_blank"
            >{{ item.user.nickname || "empty" }}</a
          >
        </div>
        <div>
          Name
          <a
            :href="`https://irnft.co/profile/${item.user.id}/bio`"
            target="_blank"
            >{{ item.user.userInfo.name || "empty" }}</a
          >
        </div>
        <div>
          Surname
          <a
            :href="`https://irnft.co/profile/${item.user.id}/bio`"
            target="_blank"
            >{{ item.user.userInfo.surname || "empty" }}</a
          >
        </div>
        <div>
          Email
          <a :href="`mailto:${item.user.userInfo.email}`" target="_blank">{{
            item.user.userInfo.email || "empty"
          }}</a>
        </div>
      </template>
      <template v-slot:item.user.socials="{ item }">
        <div>
          Discord
          <a :href="item.user.userInfo.discord" target="_blank">{{
            item.user.userInfo.discord || "empty"
          }}</a>
        </div>
        <div>
          Facebook
          <a :href="item.user.userInfo.facebook" target="_blank">{{
            item.user.userInfo.facebook || "empty"
          }}</a>
        </div>
        <div>
          Instagram
          <a :href="item.user.userInfo.instagram" target="_blank">{{
            item.user.userInfo.instagram || "empty"
          }}</a>
        </div>
        <div>
          Twitter
          <a :href="item.user.userInfo.twitter" target="_blank">{{
            item.user.userInfo.twitter || "empty"
          }}</a>
        </div>
        <div>
          Website
          <a :href="item.user.userInfo.website" target="_blank">{{
            item.user.userInfo.website || "empty"
          }}</a>
        </div>
      </template>
      <template v-slot:item.user.created="{ item }">
        {{ item.user.created | timePipe }}
      </template>
      <template v-slot:item.user.address="{ item }">
        <div>
          Polygon
          <a
            :href="`https://polygonscan.com/address/${item.user.address}`"
            target="_blank"
            >{{ item.user.address | cutHash }}</a
          >
        </div>
        <div>
          Ethereum
          <a
            :href="`https://etherscan.io/address/${item.user.address}`"
            target="_blank"
            >{{ item.user.address | cutHash }}</a
          >
        </div>
      </template>
      <template v-slot:item.actions="{ item, index }">
        <v-btn
          color="deep-orange"
          icon
          large
          @click="deleteAdminMainContentRecord(item.mainPageDataId)"
        >
          <v-icon>mdi-delete</v-icon>
        </v-btn>
        <v-tooltip bottom v-if="index !== 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="deep-orange"
              v-bind="attrs"
              large
              v-on="on"
              @click="
                updateMainPageData(
                  item.mainPageDataId,
                  index - 1,
                  item.nftToken
                    ? item.nftToken.id
                    : item.user
                    ? item.user.id
                    : item.collection
                    ? item.collection.id
                    : '',
                  index
                )
              "
            >
              <v-icon>mdi-arrow-up-bold-box</v-icon>
            </v-btn>
          </template>
          <span>Pick up in line</span>
        </v-tooltip>
        <v-tooltip bottom v-if="index < items.length - 1">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="deep-orange"
              v-bind="attrs"
              large
              v-on="on"
              @click="
                updateMainPageData(
                  item.mainPageDataId,
                  index + 1,
                  item.nftToken
                    ? item.nftToken.id
                    : item.user
                    ? item.user.id
                    : item.collection
                    ? item.collection.id
                    : '',
                  index
                )
              "
            >
              <v-icon>mdi-arrow-down-bold-box</v-icon>
            </v-btn>
          </template>
          <span>Omit in line</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <div class="text-center pa-5">
      <v-pagination
        v-if="total > 1"
        v-model="page"
        :length="total"
        :total-visible="size"
        circle
        color="pink"
        @input="appointLoadingMethod"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </v-container>
</template>

<script>
import api from "@/api/api";
import SliderConfigSelectType from "@/components/sliderConfig/SliderConfigSelectType";
import ResourceType from "@/components/sliderConfig/ResouceType";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import getMainPageSettingsMixin from "../mixins/mainPage/getMainPageSettingsMixin";
import { toTimeMixin } from "../utils/mixin";
import appointAvatarMixin from "../mixins/appointAvatarMixin";
import cutHashMixin from "../mixins/cutHashMixin";

export default {
  name: "SliderConfig",
  mixins: [
    setProcessingMixin,
    getMainPageSettingsMixin,
    toTimeMixin,
    appointAvatarMixin,
    cutHashMixin,
  ],
  components: { ResourceType, SliderConfigSelectType },
  data: () => ({
    page: 0,
    size: 10,
    tab: 0,
    total: 0,
    items: [],
    loading: false,
    selectTypeModalVisible: false,
    position: null,
    object1: null,
    object2: null,
    object3: null,
    object4: null,
    object5: null,
    objectId: null,

    slides: [{}, {}, {}, {}, {}],
  }),
  computed: {
    appointHeaders() {
      switch (this.tab) {
        case 0:
          return [
            {
              text: "Block chain id",
              value: "nftToken.blockChainId",
            },
            {
              text: "File",
              value: "nftToken.fileUrl",
            },
            {
              text: "Title",
              value: "nftToken.title",
            },
            {
              text: "Created",
              value: "nftToken.created",
            },
            {
              text: "Type",
              value: "nftToken.type",
            },
            {
              text: "Currency",
              value: "nftToken.currency",
            },
            {
              text: "Actions",
              value: "actions",
            },
          ];
        case 1:
          return [
            {
              text: "Avatar",
              value: "user.avatar",
            },
            {
              text: "Banner",
              value: "user.banner",
            },
            {
              text: "Personal data",
              value: "user.personalData",
            },
            {
              text: "Socials",
              value: "user.socials",
            },
            {
              text: "Created",
              value: "user.created",
            },
            {
              text: "Address",
              value: "user.address",
            },
            {
              text: "Actions",
              value: "actions",
            },
          ];
        case 2:
          return [
            {
              text: "File",
              value: "collection.fileUrl",
            },
            {
              text: "Title",
              value: "collection.title",
            },
            {
              text: "Created",
              value: "collection.created",
            },
            {
              text: "Actions",
              value: "actions",
            },
          ];
        case 3:
          return [
            {
              text: "Block chain id",
              value: "nftToken.blockChainId",
            },
            {
              text: "File",
              value: "nftToken.fileUrl",
            },
            {
              text: "Title",
              value: "nftToken.title",
            },
            {
              text: "Created",
              value: "nftToken.created",
            },
            {
              text: "Type",
              value: "nftToken.type",
            },
            {
              text: "Currency",
              value: "nftToken.currency",
            },
            {
              text: "Actions",
              value: "actions",
            },
          ];
        default:
          return [];
      }
    },
    productType() {
      switch (this.tab) {
        case 0:
          return "LIVE_AUCTION";
        case 1:
          return "CREATOR";
        case 2:
          return "COLLECTION";
        case 3:
          return "TOKEN";
        default:
          return "";
      }
    },
  },
  methods: {
    async updateMainPageData(dataId, position, productId, prevPosition) {
      try {
        const body = {
          position,
          productId,
          productType: this.productType,
        };

        const query = {
          dataId,
        };
        await api.updateMainPageData({ body, query });
        this.setSuccess(
          `${this.productType.toLowerCase()} position has been changed`
        );
        let items = [...this.items];
        const neighborElement = items[position];
        items[position] = items[prevPosition];
        items[prevPosition] = neighborElement;
        this.items = [...items];
      } catch (e) {
        this.setError(e.response.data.message);
      }
    },
    async deleteAdminMainContentRecord(dataId) {
      try {
        await api.deleteAdminMainContentRecord({
          dataId,
        });
        this.items = this.items.filter((i) => i.mainPageDataId !== dataId);
      } catch (e) {
        console.log(e);
        this.setError(e.response.data.message);
      }
    },
    async updateMainPageConfigs(value, type) {
      switch (type) {
        case "collections":
          this.currentCollectionsConfig = this.collectionsConfigs.find(
            (i) => i.value === value
          );
          break;
        case "creators":
          this.currentCreatorsConfig = this.creatorsConfigs.find(
            (i) => i.value === value
          );
          break;
        case "live":
          this.currentLiveAuctionsConfig = this.liveAuctionsConfigs.find(
            (i) => i.value === value
          );
          break;
        case "tokens":
          this.currentNftTokensConfig = this.nftTokensConfigs.find(
            (i) => i.value === value
          );
          break;
      }
      try {
        await api.updateMainPageConfigs({
          collections: this.currentCollectionsConfig.value,
          creators: this.currentCreatorsConfig.value,
          liveAuctions: this.currentLiveAuctionsConfig.value,
          nftTokens: this.currentNftTokensConfig.value,
        });

        this.getMainPageSettings();
      } catch (e) {
        this.setError(e.response.data.message);
      }
    },
    async toggleTab(tab) {
      this.tab = tab;
      await this.appointLoadingMethod();
    },
    showSelectTypeModal(position, objectId) {
      this.position = position;
      this.objectId = objectId;
      this.selectTypeModalVisible = true;
    },
    async getAllAdminObjects() {
      try {
        const response = await api.getAllAdminObjects();
        response.forEach((i, index) => (this.slides[index] = i));
        this.$forceUpdate();
      } catch (e) {
        console.log(e);
      }
    },
    async deleteAdminObject() {
      try {
        await api.deleteAdminObject({
          mainPageContentId: this.objectId,
        });
        this.slides = this.slides.map(() => ({}));
        this.objectId = null;
        await this.getAllAdminObjects();
        this.setSuccess("Object has been removed");
        this.selectTypeModalVisible = false;
      } catch (e) {
        console.log(e.response.data.message);
      }
    },
    async getCreatorsByHandsSettings() {
      try {
        const response = await api.getCreatorsByHandsSettings({
          page: this.page,
          size: this.size,
        });
        this.items = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getLiveAuctionsByHandsSettings() {
      try {
        const response = await api.getLiveAuctionsByHandsSettings({
          page: this.page,
          size: this.size,
        });
        this.items = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getNftCollectionsByHandsSettings() {
      try {
        const response = await api.getNftCollectionsByHandsSettings({
          page: this.page,
          size: this.size,
        });
        this.items = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async getNftTokensByHandsSettings() {
      try {
        const response = await api.getNftTokensByHandsSettings({
          page: this.page,
          size: this.size,
        });
        this.items = response.content;
        this.total = response.totalPages;
      } catch (e) {
        console.log(e);
      }
    },
    async appointLoadingMethod() {
      this.loading = true;
      switch (this.tab) {
        case 0:
          await this.getLiveAuctionsByHandsSettings();
          break;
        case 1:
          await this.getCreatorsByHandsSettings();
          break;
        case 2:
          await this.getNftCollectionsByHandsSettings();
          break;
        case 3:
          await this.getNftTokensByHandsSettings();
          break;
      }
      this.loading = false;
    },
  },
  async mounted() {
    await Promise.all([this.getAllAdminObjects(), this.getMainPageSettings()]);
  },
};
</script>
