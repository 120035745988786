<template>
  <div class="page">
    Price range
    <div class="page-inputs">
      <div class="page-inputs-input">
        <v-text-field
          variant="plain"
          placeholder="$0.00"
          label="From"
          v-model="from"
          clearable
          clear-icon="mdi-close"
        ></v-text-field>
      </div>
      <div class="page-inputs-input">
        <v-text-field
          placeholder="$0.00"
          variant="plain"
          label="To"
          v-model="to"
          clearable
          clear-icon="mdi-close"
        ></v-text-field>
      </div>
    </div>
    <v-btn color="green" @click="submit">Apply</v-btn>
  </div>
</template>

<script>
export default {
  name: "TockenRangeFilter",
  props: ["from", "to"],
  emits: ["SubmitPrice"],
  methods: {
    submit() {
      this.$emit("SubmitPrice");
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  margin-bottom: 20px;
  &-inputs {
    display: flex;
    justify-content: space-between;
    &-input {
      width: 40%;
    }
  }
}
</style>
