<template>
  <div class="page">
    Status
    <FIterCheckbox
      v-for="(item, index) in types"
      :key="index"
      :value="item.value"
      :label="item.label"
      :data="data"
      @change="checkboxClick"
    />
  </div>
</template>

<script>
import FIterCheckbox from "./FIterCheckbox.vue";
export default {
  name: "TockenStatusFilter",
  props: ["data"],
  emit: ["ChangeStatus"],
  data: () => ({
    types: [
      {
        label: "No auction",
        value: "NO_AUCTION",
      },
      {
        label: "Fixed price",
        value: "ON_SIMPLE_AUCTION",
      },
      {
        label: "Time auction",
        value: "ON_TIME_AUCTION",
      },
    ],
    chosenStatus: [],
  }),
  methods: {
    checkboxClick(data) {
      if (data.add) this.chosenStatus.push(data.value);
      else {
        this.chosenStatus = this.chosenStatus.filter((i) => i !== data.value);
      }
      this.$emit("ChangeStatus", this.chosenStatus);
    },
  },
  components: {
    FIterCheckbox,
  },
};
</script>
