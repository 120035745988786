import axios from "axios";
import store from "@/store";
import router from "@/router";
import baseURL from "@/api/config";

const http = axios.create({
    baseURL: baseURL,
    timeout: 50000,
    contentType: "application/json"
});

const PUBLIC_URLS = [
    "/admin/auth",
    "/auth/sign-token"
]

http.axios = axios;

http.interceptors.request.use(function (config) {
    if(!PUBLIC_URLS.includes(config.url)) {

        let token = localStorage.getItem('accessToken')
        if(token) {
            config.headers.Authorization = `Bearer_${token}`
        }
        else {
            throw new axios.Cancel('USER_CANCEL')
        }
    }
    return config;
}, function () {
    store.commit('user/unsetUser');
    if(router.currentRoute.meta.requiresAuth) {
        router.push('/sign-in')
    }
});


http.interceptors.response.use(
    response => response,
    error => {
        if(error.message === 'USER_CANCEL') {
            store.commit('user/unsetUser');
            router.push('/sign-in')
            return Promise.reject(error)
        }
        let status = error.response?.data.status;
        if(error?.response?.data.message === "Access is denied") {
            store.commit('general/setError', "Not enough rights");
            setTimeout(() => {
                store.commit('general/setError', null);
            }, 4000)
        }
        if(status === 401 || status===403) {
            store.commit('user/unsetUser');
            if(router.currentRoute.meta.requiresAuth) {
                router.push('/sign-in')
            }
            return Promise.reject(error);
        }
        return Promise.reject(error)
    }
);

export default http;
