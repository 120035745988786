<template>
  <div>
    <div class="search">
      <SearchInput
        placeholder="Nickname"
        @clear="getNftCollections"
        @search="searchCollections"
      />
    </div>
    <v-data-table
      :headers="headers"
      :items="collections"
      item-key="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="size"
    >
      <template v-slot:item.fileUrl="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="item.fileUrl"
        />
      </template>
      <template v-slot:item.selectCollection="{ item }">
        <v-btn color="primary" @click="selectCollection(item.id)"
          >Select collection</v-btn
        >
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | timePipe }}
      </template>
      <template v-slot:item.settings="{ item }">
        <CollectionSettingsPopover
          :collection-id="item.id"
          :allow-hands-settings="allowCollectionsHandsSettings"
          :token-status="item.visibleStatus"
          @visibilityCahnged="getNftCollections"
        />
      </template>
    </v-data-table>
    <div class="text-center pa-5">
      <v-pagination
        v-if="total > 1"
        v-model="page"
        :length="total"
        :total-visible="size"
        circle
        color="primary"
        @input="getNftCollections"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import { toTimeMixin } from "@/utils/mixin";
import createAdminObjectMixin from "@/mixins/createAdminObjectMixin";
import CollectionSettingsPopover from "../components/collections/CollectionSettingsPopover";
import getMainPageSettingsMixin from "../mixins/mainPage/getMainPageSettingsMixin";

import SearchInput from "../components/Filters/SearchInput.vue";

export default {
  name: "Collections",
  components: { CollectionSettingsPopover, SearchInput },
  mixins: [toTimeMixin, createAdminObjectMixin, getMainPageSettingsMixin],
  data: () => ({
    totalElements: 0,
    collections: [],
    loading: false,
    page: 1,
    size: 20,
    total: 0,
  }),
  computed: {
    showSelectTokenBtn() {
      return this.$route.query.position;
    },
    headers() {
      if (this.showSelectTokenBtn) {
        return [
          {
            text: "File",
            value: "fileUrl",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Created",
            value: "created",
          },
          {
            text: "SelectCollection",
            value: "selectCollection",
          },
        ];
      } else {
        return [
          {
            text: "File",
            value: "fileUrl",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Visibility",
            value: "visibleStatus",
          },
          {
            text: "Created",
            value: "created",
          },
          {
            text: "Settings",
            value: "settings",
          },
        ];
      }
    },
  },
  methods: {
    async searchCollections(data) {
      try {
        this.loading = true;
        const response = await api.searchNftCollections({
          page: this.page - 1,
          size: this.size,
          search: data,
        });
        this.collections = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async selectCollection(nftCollectionId) {
      await this.createAdminObjectObject({
        position: this.$route.query.position,
        nftCollectionId,
        nftTokenId: null,
        type: "COLLECTION",
      });
    },
    async getNftCollections() {
      try {
        this.loading = true;
        const response = await api.getNftCollections({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        this.collections = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getNftCollections();
    this.getMainPageSettings();
  },
};
</script>

<style scoped>
.search {
  margin: 20px 0 10px 0;
}
</style>
