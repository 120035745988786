<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon v-on="on">
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Set user position</v-list-item-title>
            <v-list-item-subtitle v-if="!allowHandsSettings"
              >To select the display order, select the manual setting on the
              homepage settings page
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  color="pink"
                  type="number"
                  v-model="position"
                  :placeholder="'Set position'"
                  :readonly="!allowHandsSettings"
                />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="deep-orange"
                      v-bind="attrs"
                      large
                      :disabled="
                        (!allowHandsSettings || !position || position < -1) &&
                        position !== 0
                      "
                      v-on="on"
                      @click="addMainPageData(userId, 'CREATOR')"
                    >
                      <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span>Save user position</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import setProcessingMixin from "../../mixins/setProcessingMixin";
import updateMainPageDataMixin from "../../mixins/mainPage/updateMainPageDataMixin";

export default {
  name: "UserSettingsPopover",
  mixins: [setProcessingMixin, updateMainPageDataMixin],
  props: {
    userId: {
      type: String,
      required: true,
    },
    allowHandsSettings: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
};
</script>
