var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"pink","dark":""}},'v-btn',attrs,false),on),[_vm._v("Answer")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"pink","dark":""}},[_vm._v("Answer report")]),_c('v-card-text',[_c('v-textarea',{attrs:{"name":"input-7-1","label":"Type your answer here"},model:{value:(_vm.answer),callback:function ($$v) {_vm.answer=$$v},expression:"answer"}})],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":() => {
              dialog.value = false
            }}},[_vm._v("Save")]),_c('v-btn',{attrs:{"text":""},on:{"click":() => {
              _vm.answerReport()
                .then(() => {
                  dialog.value = false
                })
            }}},[_vm._v("Save")])],1)],1)]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }