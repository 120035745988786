<template>
  <div class="main">
    <div class="main-filter">
      <FilterComponent
        :allTokents="tokens"
        :searchInput="searchInput"
        @clear="loadTokens"
        @filter="setFilteredTokens"
      />
    </div>
    <div class="main-table">
      <div class="main-table-header">
        <SearchInput
          placeholder="Title"
          @clear="searchTockens"
          @search="searchTockens"
        />
      </div>

      <v-data-table
        :headers="headers"
        :items="tokens"
        item-key="name"
        class="elevation-1"
        :loading="loading"
        loading-text="Loading... Please wait"
        hide-default-footer
        :items-per-page="size"
      >
        <template v-slot:item.fileUrl="{ item }">
          <v-img
            width="50"
            height="50"
            style="border-radius: 50%; margin: 20px 0"
            :src="item.fileUrl"
            v-if="item.type !== 'VIDEO' && item.type !== 'AUDIO'"
          />
          <video
            width="50"
            height="50"
            style="border-radius: 50%; margin: 20px 0"
            :src="item.fileUrl"
            v-if="item.type === 'VIDEO'"
          />
        </template>
        <template v-slot:item.selectToken="{ item }">
          <v-btn color="primary" @click="selectToken(item.id)"
            >Select token</v-btn
          >
        </template>
        <template v-slot:item.created="{ item }">
          {{ item.created | timePipe }}
        </template>
        <template v-slot:item.status="{ item }">
          <div v-if="item.status === 'NO_AUCTION'">
            {{ item.activeAuction?.type | stringPipe }}
          </div>
          <div v-else>
            {{ item.activeAuction.type | stringPipe }}
            <OverlayContent :selectedItem="item" />
          </div>
        </template>
        <template v-slot:item.settings="{ item }">
          <TokenSettingsPopover
            :token-id="item.id"
            :allow-hands-settings="
              (item.activeAuction &&
                item.activeAuction === 'TIME_AUCTION' &&
                allowAuctionsHandsSettings) ||
              (!item.activeAuction && allowTokensHandsSettings) ||
              (item.activeAuction &&
                item.activeAuction !== 'TIME_AUCTION' &&
                allowTokensHandsSettings)
            "
            :is-active-time-auction="
              item.activeAuction && item.activeAuction === 'TIME_AUCTION'
            "
            :tokenStatus="item.visibleStatus"
            :tokenId="item.id"
            :block-chain-id="item.blockChainId"
            @visibilityCahnged="visibilityCahnged"
          />
        </template>
      </v-data-table>
      <div class="text-center pa-5">
        <v-pagination
          v-if="total > 1"
          v-model="page"
          :length="total"
          :total-visible="size"
          circle
          color="pink"
          @input="loadTokens"
          prev-icon="mdi-menu-left"
          next-icon="mdi-menu-right"
        ></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import { toTimeMixin, snakeCaseNormalizer } from "@/utils/mixin";
import createAdminObjectMixin from "@/mixins/createAdminObjectMixin";
import setProcessingMixin from "../mixins/setProcessingMixin";
import TokenSettingsPopover from "../components/tokens/TokenSettingsPopover";
import getMainPageSettingsMixin from "../mixins/mainPage/getMainPageSettingsMixin";

import FilterComponent from "../components/Filters/Filter.vue";
import SearchInput from "../components/Filters/SearchInput.vue";
import OverlayContent from "../components/tokens/OverlayContent.vue";

export default {
  name: "Tokens",
  components: {
    FilterComponent,
    SearchInput,
    OverlayContent,
    TokenSettingsPopover,
  },
  mixins: [
    snakeCaseNormalizer,
    toTimeMixin,
    createAdminObjectMixin,
    setProcessingMixin,
    getMainPageSettingsMixin,
  ],
  data: () => ({
    searchInput: "",
    selectedItem: {},
    totalElements: 0,
    tokens: [],
    loading: false,
    page: 1,
    size: 20,
    total: 0,
  }),
  computed: {
    showSelectTokenBtn() {
      return this.$route.query.position;
    },
    headers() {
      if (this.showSelectTokenBtn) {
        return [
          {
            text: "Block chain id",
            value: "blockChainId",
          },
          {
            text: "File",
            value: "fileUrl",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Created",
            value: "created",
          },
          {
            text: "Type",
            value: "type",
          },
          {
            text: "Currency",
            value: "currency",
          },
          {
            text: "Select token",
            value: "selectToken",
          },
        ];
      } else {
        return [
          {
            text: "Block chain id",
            value: "blockChainId",
          },
          {
            text: "File",
            value: "fileUrl",
          },
          {
            text: "Title",
            value: "title",
          },
          {
            text: "Created",
            value: "created",
          },
          {
            text: "Type",
            value: "type",
          },
          {
            text: "Auction",
            value: "status",
          },
          {
            text: "Currency",
            value: "currency",
          },
          {
            text: "Settings",
            value: "settings",
          },
        ];
      }
    },
  },
  methods: {
    visibilityCahnged() {
      this.loadTokens();
    },
    searchTockens(data) {
      this.searchInput = data;
    },
    setFilteredTokens(data) {
      (this.tokens = data.tokens),
        (this.total = data.total),
        (this.totalElements = data.totalElements);
    },
    async selectToken(nftTokenId) {
      await this.createAdminObjectObject({
        position: this.$route.query.position,
        nftCollectionId: null,
        nftTokenId,
        type: "TOKEN",
      });
    },
    async loadTokens() {
      try {
        this.loading = true;
        const response = await api.getAllNftTokens({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        console.log(response.content);
        this.tokens = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.loadTokens();
    this.getMainPageSettings();
  },
};
</script>
<style lang="scss" scoped>
.main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  &-table {
    width: 100%;
    &-header {
      margin: 20px 0 10px 0;
    }
  }
  &-filter {
    width: 15vw;
    margin: 20px;
  }
}
</style>
