<template>
  <v-text-field
    v-model="searchInputData"
    label="Search"
    :placeholder="placeholder"
    prepend-inner-icon="mdi-magnify"
    clearable
    clear-icon="mdi-close"
    @keyup.enter="searchButtonClick"
    @click:prepend-inner="searchButtonClick"
    @click:clear="clearButtonClick"
  ></v-text-field>
</template>

<script>
export default {
  name: "SearchInput",
  props: ["placeholder"],
  emit: ["clear", "search"],
  data: () => ({
    searchInputData: "",
  }),
  methods: {
    clearButtonClick() {
      this.$emit("clear", "");
    },
    searchButtonClick() {
      if (this.searchInputData === "") {
        this.$emit("clear", "");
      } else {
        this.$emit("search", this.searchInputData);
      }
    },
  },
};
</script>
