<template>
  <v-row justify="center">
    <v-col :md="6" :xs="12" :sm="12">
      <v-card :elevation="20" style="margin-top: 150px">
        <v-card-title>
          <h1 class="font-weight-light">Q NFT admin</h1>
        </v-card-title>
        <v-card-text>
          <v-form
              class="mb-4"
              ref="form"
              lazy-validation
              @submit.prevent="auth"
          >
            <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="pink"
                    class="mb-3"
                    large
                    block
                    dark
                    v-bind="attrs"
                    v-on="on"
                >
                  {{currency.title}}
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                    @click="setCurrency(item)"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn dark type="submit" block large color="pink"
                   class="mr-4">Submit</v-btn>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import contract from "@/api/contract";
import {mapActions, mapMutations} from "vuex";
import api from "@/api/api";
import router from "@/router";

export default {
  name: "SignIn",
  data: () => ({
    items: [
      { title: 'POLYGON', value: "MATIC" },
      { title: 'ETHEREUM', value: "ETH" },
    ],
    currency: {title: "POLYGON", value: "MATIC"},
  }),
  methods: {
    ...mapActions({
      connectWallet: "wallet/connectWallet",
      logOut: "user/logOut",
      setChain: 'wallet/setChain',
    }),
    ...mapMutations({
      setToken: "user/setToken",
      setLoading: "general/setLoading",
      setError: "general/setError",
      setIsEthereumDetected: "wallet/setIsEthereumDetected",
    }),
    setCurrency(value) {
      this.currency = value;
    },
    checkEthereum() {
      const { ethereum } = window;
      return Boolean(ethereum && ethereum.isMetaMask);
    },
    checkConnectMethod(connectMethod) {
      return connectMethod || localStorage.getItem("connectionMethod");
    },
    async tryConnect(connectMethod) {
      try {
        this.setIsEthereumDetected(this.checkEthereum());
        return await this.connectWallet(this.checkConnectMethod(connectMethod));
      } catch (e) {
        console.log(e);
        if (e.code === -32002 && router.currentRoute.name === "SignIn") {
          e.message = "Connect wallet request has been sent, check your wallet";
          throw e;
        }
      }
    },
    async auth() {
      try {
        this.setLoading(true);
        const connectResult = await this.tryConnect("metamask");
        await this.setChain({ symbol: this.currency.value, throwEx: true });
        const signToken = await api.getSignToken({
          address: connectResult.address,
        });
        const signature = await contract.sign(connectResult.address, signToken);
        signature.currency = this.currency.value;
        const response = await api.auth(signature);
        this.setToken(response.accessToken);
        this.setLoading(false);
        await this.$router.push("/");
      } catch (e) {
        const walletError = e.message;
        const serverError = e?.response?.data.message;
        this.setError(serverError || walletError || "Unknown error occurred");
        this.setLoading(false);
      }
    },
  }
}
</script>

