import setProcessingMixin from "../setProcessingMixin";
import api from "../../api/api";

export default {
  mixins: [setProcessingMixin],
  data: () => ({
    position: 0,
  }),
  methods: {
    async addMainPageData(productId, productType, type) {
      try {
        await api.addMainPageData(
          {
            position: +this.position,
            productId,
            productType,
          },
          type
        );
        this.setSuccess(`${productType.toLowerCase()} position has been set`);
      } catch (e) {
        this.setError(e.response.data.message);
      }
    },
  },
};
