<template>
  <div>
    <div class="search">
      <SearchInput
        placeholder="Nickname"
        @clear="getUsers"
        @search="searchUsers"
      />
    </div>
    <v-tab>
      <v-tab-item>Active</v-tab-item>
    </v-tab>
    <v-data-table
      :headers="headers"
      :items="users"
      item-key="name"
      class="elevation-1"
      :loading="loading"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="size"
    >
      <template v-slot:item.avatar="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="appointAvatar(item.userInfo.avatarUrl)"
        />
      </template>
      <template v-slot:item.banner="{ item }">
        <v-img
          width="50"
          height="50"
          style="border-radius: 50%; margin: 20px 0"
          :src="appointAvatar(item.userInfo.bannerUrl)"
        />
      </template>
      <template v-slot:item.personalData="{ item }">
        <div>
          Nickname
          <a
            :href="`https://irnft.co/profile/${item.id}/bio`"
            target="_blank"
            >{{ item.nickname || "empty" }}</a
          >
        </div>
        <div>
          Name
          <a
            :href="`https://irnft.co/profile/${item.id}/bio`"
            target="_blank"
            >{{ item.userInfo.name || "empty" }}</a
          >
        </div>
        <div>
          Surname
          <a
            :href="`https://irnft.co/profile/${item.id}/bio`"
            target="_blank"
            >{{ item.userInfo.surname || "empty" }}</a
          >
        </div>
        <div>
          Email
          <a :href="`mailto:${item.userInfo.email}`" target="_blank">{{
            item.userInfo.email || "empty"
          }}</a>
        </div>
      </template>
      <template v-slot:item.socials="{ item }">
        <div>
          Discord
          <a :href="item.userInfo.discord" target="_blank">{{
            item.userInfo.discord || "empty"
          }}</a>
        </div>
        <div>
          Facebook
          <a :href="item.userInfo.facebook" target="_blank">{{
            item.userInfo.facebook || "empty"
          }}</a>
        </div>
        <div>
          Instagram
          <a :href="item.userInfo.instagram" target="_blank">{{
            item.userInfo.instagram || "empty"
          }}</a>
        </div>
        <div>
          Twitter
          <a :href="item.userInfo.twitter" target="_blank">{{
            item.userInfo.twitter || "empty"
          }}</a>
        </div>
        <div>
          Website
          <a :href="item.userInfo.website" target="_blank">{{
            item.userInfo.website || "empty"
          }}</a>
        </div>
      </template>
      <template v-slot:item.created="{ item }">
        {{ item.created | timePipe }}
      </template>
      <template v-slot:item.address="{ item }">
        <div>
          Polygon
          <a
            :href="`https://polygonscan.com/address/${item.address}`"
            target="_blank"
            >{{ item.address | cutHash }}</a
          >
        </div>
        <div>
          Ethereum
          <a
            :href="`https://etherscan.io/address/${item.address}`"
            target="_blank"
            >{{ item.address | cutHash }}</a
          >
        </div>
      </template>
      <template v-slot:item.roles="{ item }">
        <ToggleRole
          :roles="['ADMIN', 'MODERATOR', 'USER']"
          :userId="item.id"
          :role="item.role"
          @toggleRole="changeRole"
        />
      </template>
      <template v-slot:item.settings="{ item }">
        <UserSettingsPopover
          :user-id="item.id"
          :allow-hands-settings="allowCreatorsHandsSettings"
        />
      </template>
    </v-data-table>
    <div class="text-center pa-5">
      <v-pagination
        v-if="total > 1"
        v-model="page"
        :length="total"
        :total-visible="size"
        circle
        color="primary"
        @input="getUsers"
        prev-icon="mdi-menu-left"
        next-icon="mdi-menu-right"
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import api from "../api/api";
import { toTimeMixin } from "@/utils/mixin";
import appointAvatarMixin from "@/mixins/appointAvatarMixin";
import cutHashMixin from "@/mixins/cutHashMixin";

import ToggleRole from "../components/users/ToggleRole";
import UserSettingsPopover from "../components/users/UserSettingsPopover";
import getMainPageSettingsMixin from "../mixins/mainPage/getMainPageSettingsMixin";
import SearchInput from "../components/Filters/SearchInput.vue";

export default {
  name: "Users",
  components: { UserSettingsPopover, ToggleRole, SearchInput },
  mixins: [
    toTimeMixin,
    appointAvatarMixin,
    cutHashMixin,
    getMainPageSettingsMixin,
  ],
  data: () => ({
    totalElements: 0,
    users: [],
    loading: false,
    page: 1,
    size: 20,
    total: 0,
    roles: [],
  }),
  computed: {
    headers() {
      return [
        {
          text: "Avatar",
          value: "avatar",
        },
        {
          text: "Banner",
          value: "banner",
        },
        {
          text: "Personal data",
          value: "personalData",
        },
        {
          text: "Socials",
          value: "socials",
        },
        {
          text: "Created",
          value: "created",
        },
        {
          text: "Address",
          value: "address",
        },
        {
          text: "Settings",
          value: "settings",
        },
      ];
    },
  },
  methods: {
    changeRole(payload) {
      api.changeRole({
        role: payload.role,
        userId: payload.userId,
      });
    },
    async searchUsers(data) {
      try {
        this.loading = true;
        const response = await api.searchUsersByNickname({
          page: this.page - 1,
          size: this.size,
          search: data,
        });
        this.users = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    async getUsers() {
      try {
        this.loading = true;
        const response = await api.getUsers({
          page: this.page - 1,
          size: this.size,
          sort: "created,desc",
        });
        this.users = response.content;
        this.total = response.totalPages;
        this.totalElements = response.totalElements;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  mounted() {
    this.getUsers();
    this.getMainPageSettings();
  },
};
</script>

<style scoped>
.search {
  margin: 20px 0 10px 0;
}
</style>
