<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon v-on="on">
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Change visibility to
              {{
                tokenStatus === "PUBLIC" ? "private" : "public"
              }}</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              color="deep-orange"
              @click="changeVisibility(collectionId)"
              large
            >
              <v-icon>{{
                `mdi-eye${tokenStatus === "PUBLIC" ? "-off-" : "-"}outline`
              }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>Set collection position</v-list-item-title>
            <v-list-item-subtitle v-if="!allowHandsSettings"
              >To select the display order, select the manual setting on the
              homepage settings page
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  type="number"
                  v-model="position"
                  :placeholder="'Set priority'"
                  :readonly="!allowHandsSettings"
                />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="deep-orange"
                      v-bind="attrs"
                      large
                      :disabled="
                        (!allowHandsSettings || !position || position < 0) &&
                        position !== 0
                      "
                      v-on="on"
                      @click="addMainPageData(collectionId, 'COLLECTION')"
                    >
                      <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span>Save collection position</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import setProcessingMixin from "../../mixins/setProcessingMixin";
import updateMainPageDataMixin from "../../mixins/mainPage/updateMainPageDataMixin";
import api from "../../api/api";

export default {
  name: "CollectionSettingsPopover",
  mixins: [setProcessingMixin, updateMainPageDataMixin],
  props: {
    collectionId: {
      type: Number,
      required: true,
    },
    allowHandsSettings: {
      type: Boolean,
      required: true,
    },
    tokenStatus: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    async changeVisibility(collectionId) {
      try {
        const dialog = await this.$swal({
          title: `Do you want to make this collection ${
            this.tokenStatus === "PUBLIC" ? "private" : "public"
          }?`,
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
        });
        if (dialog.isConfirmed) {
          this.setLoading(true);
          console.log(this.tokenStatus);
          await api.changeCollectionVisible({
            collectionId: collectionId,
            status: this.tokenStatus !== "PUBLIC" ? "PUBLIC" : "PRIVATE",
          });
          this.$emit("visibilityCahnged", {
            id: collectionId,
            status: this.tokenStatus,
          });
        }
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
