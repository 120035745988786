import Web3 from "web3";
import contract from "@/api/contract";

import networks from "@/utils/networks";

export default {
    namespaced: true,
    state: {
        address: null,
        balance: null,
        chainId: null,
        provider: null,
        currentNetwork: null,
        isEthereumDetected: false,
        availableNetworks: ["ethereum", "polygon"],
        network: localStorage.getItem("network") || "ethereum",
        currentConnectionMethod: localStorage.getItem("connectionMethod"),
    },
    mutations: {
        unsetWallet(state) {
            contract.initWeb3Methods(null);
            state.chainId = null;
            state.balance = null;
            state.address = null;
            state.currentConnectionMethod = null;
            localStorage.removeItem("connectionMethod");
        },
        setNetwork(state, payload) {
            state.network = payload;
            localStorage.setItem("network", payload);
        },
        setChainId(state, payload) {
            state.chainId = payload;
        },
        setBalance(state, payload) {
            state.balance = payload;
        },
        setAddress(state, payload) {
            state.address = payload;
        },
        setProvider(state, payload) {
            state.provider = payload;
        },
        setIsEthereumDetected(state, payload) {
            state.isEthereumDetected = payload;
        },
    },
    actions: {
        setDefaultProvider({ rootState }) {
            window.web3 = new Web3(
                new Web3.providers.HttpProvider(
                    networks.find((i) => i.network === rootState.wallet.network).rpcUrl
                )
            );
        },
        async setChain({ rootState, state }, payload) {
            let network = null;
            if (payload.symbol) {
                network = networks.find((i) => i.symbol === payload.symbol);
            } else {
                network = networks.find((i) => i.network === rootState.wallet.network);
            }
            try {
                await state.provider.request({
                    method: "wallet_switchEthereumChain",
                    params: [
                        {
                            chainId: network.chainIdHex,
                        },
                    ],
                });
            } catch (e) {
                if(e.code === 4001 && payload.throwEx) {
                    throw e;
                }
                try {
                    await state.provider.request({
                        method: "wallet_addEthereumChain",
                        params: [
                            {
                                chainId: network.chainIdDec,
                                rpcUrls: [network.rpcUrl],
                                chainName: network.name,
                                nativeCurrency: {
                                    name: network.symbol,
                                    symbol: network.symbol,
                                    decimals: network.decimals,
                                },
                                blockExplorerUrls: [network.blockExplorerUrl],
                            },
                        ],
                    });
                    await state.provider.request({
                        method: "wallet_switchEthereumChain",
                        params: [
                            {
                                chainId: network.chainIdHex,
                            },
                        ],
                    });
                } catch (e) {
                    console.log(e);
                }
            }
        },
        async connectWallet({ commit, rootState, dispatch }, connectionMethod) {
            if (
                connectionMethod === "metamask" &&
                !rootState.wallet.isEthereumDetected
            ) {
                dispatch("setDefaultProvider");
                window.open(
                    "https://metamask.app.link/dapp/display.flexychain.com/home",
                    "_blank"
                );
                throw new Error("Ethereum not detected");
            }
            let provider = null;
            if (connectionMethod === "metamask") {
                await window.ethereum.request({ method: "eth_requestAccounts" });
                provider = window.ethereum;
                window.web3 = new Web3(window.ethereum);
                commit("setProvider", provider);
                //await dispatch("setChain", {});
            }
            if (!connectionMethod) {
                dispatch("setDefaultProvider");
                return;
            }
            commit("setProvider", provider);
            const accounts = await window.web3.eth.getAccounts();
            let balance = await window.web3.eth.getBalance(accounts[0]);
            let chainId = await window.web3.eth.getChainId();
            commit("setAddress", accounts[0]);
            commit("setBalance", balance);
            commit("setChainId", chainId);
            console.log(accounts[0]);
            console.log(balance);
            console.log(chainId);
            contract.initWeb3Methods(accounts[0]);
            localStorage.setItem("connectionMethod", connectionMethod);

            if (provider && connectionMethod !== "fortmatic") {
                provider.on("chainChanged", async (res) => {
                    //if (rootState.user.isAuthorized) {
                        balance = await window.web3.eth.getBalance(accounts[0]);
                        const network = networks.find(
                            (i) => i.chainIdDec === res || i.chainIdHex === res
                        );
                        commit("setChainId", res);
                        contract.initWeb3Methods(accounts[0]);
                        commit("setBalance", balance);
                        if (network) {
                            commit("setNetwork", network.network);
                        }
                        if (rootState.wallet.currentConnectionMethod === "metamask") {
                            await dispatch("setChain", {});
                        }
                    //}
                });
                provider.on("accountsChanged", async () => {
                    if (rootState.user.isAuthorized) {
                        commit("unsetWallet");
                        dispatch("user/logOut", {}, { root: true });
                    }
                });
            }
            return {address: accounts[0]}
        },
    },
    getters: {
        getAvailableNetworks: (state) => state.availableNetworks,
        getNetwork: (state) => state.network,
        getMultiplier: (state) => {
            let network = networks.find(
                (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
            );
            if (network) {
                return network.multiplier;
            } else return 0;
        },
        getSymbol: (state) => {
            let network = networks.find(
                (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
            );
            if (network) {
                return network.symbol;
            } else return "";
        },
        getIsEthereumDetected: (state) => state.isEthereumDetected,
        getContract: (state) => {
            console.log(state.chaindId)
            let network = networks.find(
                (i) => i.chainIdHex === state.chainId || i.chainIdDec === state.chainId
            );
            if (network) {
                return network.adminContract;
            } else return "";
        },
        walletConnected: (state) => !!state.address,
        getBalance: (state) => Number((state.balance / 1e18).toFixed(4)),
        getAddress: (state) => state.address,
        isWrongChainId: (state, getters, rootState) => {
            return (
                state.chainId &&
                rootState.user.isAuthorized &&
                !networks.find((i) => i.chainIdDec === state.chainId) &&
                !networks.find((i) => i.chainIdHex === state.chainId)
            );
        },
    },
};
