<template>
  <v-app-bar app dense>
    <v-app-bar-nav-icon @click="setDrawerEvent"/>
    <v-toolbar-title>{{currentPage}}</v-toolbar-title>

    <v-spacer></v-spacer>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
            color="pink"
            dark
            v-bind="attrs"
            v-on="on"
            class="mr-5"
        >
          {{getSymbol || 'Wrong network'}}
        </v-btn>
      </template>
      <v-list>
        <v-list-item
            v-for="(item, index) in items"
            :key="index"
        >
          <v-list-item-title @click="changeNetwork(item.title)">{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <v-btn
        class="mb-2 mt-2"
        fab
        dark
        small
        title="log out"
        color="pink"
        @click="logOut"
    >
      <v-icon dark>
        mdi-exit-to-app
      </v-icon>
    </v-btn>

  </v-app-bar>
</template>

<script>
import {mapActions, mapGetters, mapMutations} from "vuex";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import api from "../../api/api";
import contract from "../../api/contract";

  export default {
    name: 'AppBar',
    mixins: [setProcessingMixin],
    data: () => ({
      innerWidth: window.innerWidth,
      manualSetDrawer: false,
      items: [
        { title: 'ETH' },
        { title: 'MATIC' },
      ],
    }),
    computed: {
      ...mapGetters({
        getSymbol: "wallet/getSymbol",
        getAddress: "wallet/getAddress"
      }),
      currentPage() {
        return this.$route.name.split('_').join(' ');
      }
    },
    methods: {
      ...mapActions({
        setChain: 'wallet/setChain',
      }),
      ...mapMutations({
        unsetUser: 'user/unsetUser',
        setDrawer: 'general/setDrawer',
        setToken: 'user/setToken',
      }),
      async changeNetwork(symbol) {
        try {
          this.setLoading(true);
          const signToken = await api.getSignToken({
            address: this.getAddress,
          });
          await this.setChain({ symbol, throwEx: true });
          const signature = await contract.sign(this.getAddress, signToken);
          signature.currency = symbol;
          const response = await api.auth(signature);
          this.setToken(response.accessToken);
        } catch (e) {
          this.setError(e?.message || e?.response.data.message || "unknown error");
        } finally {
          this.setLoading(false);
        }
      },
      setDrawerEvent() {
        this.manualSetDrawer = true;
        this.setDrawer();
      },
      logOut() {
        this.unsetUser();
        this.$router.push('/sign-in');
      }
    },
    mounted() {
      window.addEventListener('resize', ev => {
        this.innerWidth = ev.target.innerWidth;
        if(!this.manualSetDrawer) {
          if(this.innerWidth <= 1090) {
            this.setDrawer(false);
          } else {
            this.setDrawer(true);
          }
        } else {
          this.manualSetDrawer = false;
        }

      })
    }
  }
</script>
