<template>
  <div style="height: 100%">
    <img style="width: 100%; height: 100%" :src="fileUrl" v-if="isImage" alt="token" />
    <video style="width: 100%; height: 100%" :src="fileUrl" v-if="isVideo" autoplay controls muted loop/>
  </div>
</template>

<script>
export default {
  name: "ResourceType",
  props: {
    fileUrl: {
      type: String,
      required: true,
    },
    fileType: {
      type: String,
      required: true
    }
  },
  computed: {
    isImage() {
      return this.fileType === 'IMAGE' || this.fileType === 'GIF';
    },
    isVideo() {
      return this.fileType === 'VIDEO';
    }
  }
}
</script>