<template>
  <v-navigation-drawer
    app
    fixed
    dark
    src="@/assets/img/drawer-bg.jpg"
    :mini-variant="getDrawer"
    :mini-variant-width="60"
    permanent
  >
    <v-list-item>
      <v-list-item-avatar>
        <v-img src="@/assets/img/qnft.png"></v-img>
      </v-list-item-avatar>
      <v-list-item-title class="text-h6"> Q NFT admin </v-list-item-title>
    </v-list-item>

    <v-divider></v-divider>

    <v-list dense nav>
      <v-list-item
        v-for="([icon, text, path], i) in items"
        :to="path"
        :key="i"
        link
      >
        <v-list-item-icon>
          <v-icon>{{ icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>
            {{ text }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    items: [
      ["mdi-apps", "Main page settings", "/main-page-settings"],
      ["mdi-account-group", "Users", "/users"],
      // ['mdi-shape', 'Categories', '/categories'],
      // ['mdi-account-multiple-plus', 'Verification', '/verification'],
      ["mdi-file-multiple", "Tokens", "/tokens"],
      ["mdi-view-gallery", "Collections", "/collections"],
      ["mdi-ticket-confirmation ", "Tickets", "/tickets"],
      ["mdi-cogs", "Settings", "/settings"],
    ],
  }),
  computed: {
    ...mapGetters({
      getDrawer: "general/getDrawer",
    }),
  },
};
</script>
