<template>
  <v-footer app class="d-flex justify-end" style="font-size: 12px">
    <span>Q NFT {{new Date().getFullYear()}}</span>
  </v-footer>
</template>

<script>
  export default {
    name: 'AppFooter'
  }
</script>
