export default [
  {
    network: "polygon",
    chainIdDec: 137,
    chainIdHex: "0x89",
    multiplier: 1e18,
    rpcUrl: "https://polygon-rpc.com/",
    symbol: "MATIC",
    icon: "",
    name: "Polygon",
    decimals: 18,
    blockExplorerUrl: "https://polygonscan.com/",
    nftContract: "0xA11C80e34B6923EB82D562b706290157ce0306C1",
    marketContract: "0xD1b106D4D39a60bb71b41043a804296AfFeD5798",
    adminContract: "0x9851f14C7aB741755f5ceBFB5ec816f9db51ee8b",
    forwarderContract: "0x84dD0A60bD20C51e7656cE82d1d3ED8B6574184E",
  },
  {
    network: "ethereum",
    chainIdDec: 1,
    chainIdHex: "0x1",
    multiplier: 1e18,
    rpcUrl: "https://eth.meowrpc.com",
    symbol: "ETH",
    icon: "",
    name: "Ethereum",
    decimals: 18,
    blockExplorerUrl: "https://etherscan.io",
    nftContract: "0xA11C80e34B6923EB82D562b706290157ce0306C1",
    marketContract: "0xD1b106D4D39a60bb71b41043a804296AfFeD5798",
    adminContract: "0x9851f14C7aB741755f5ceBFB5ec816f9db51ee8b",
    forwarderContract: "0x84dD0A60bD20C51e7656cE82d1d3ED8B6574184E",
  },
];
