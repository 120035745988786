<template>
  <div class="page">
    Type
    <FIterCheckbox
      v-for="(item, index) in types"
      :key="index"
      :value="item.value"
      :label="item.label"
      :data="data"
      @change="checkboxClick"
    />
  </div>
</template>

<script>
import FIterCheckbox from "./FIterCheckbox.vue";
export default {
  name: "TockenTypeFilter",
  props: ["data"],
  emit: ["ChangeType"],
  data: () => ({
    types: [
      {
        label: "Image",
        value: "IMAGE",
      },
      {
        label: "Video",
        value: "VIDEO",
      },
      {
        label: "Gif",
        value: "GIF",
      },
      {
        label: "Audio",
        value: "AUDIO",
      },
    ],
    chosenTypes: [],
  }),
  methods: {
    checkboxClick(data) {
      if (data.add) this.chosenTypes.push(data.value);
      else {
        this.chosenTypes = this.chosenTypes.filter((i) => i !== data.value);
      }
      this.$emit("ChangeType", this.chosenTypes);
    },
  },
  components: {
    FIterCheckbox,
  },
};
</script>
