<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="400"
    offset-x
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" icon v-on="on">
        <v-icon>mdi-cogs</v-icon>
      </v-btn>
    </template>

    <v-card>
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Change visibility to
              {{
                tokenStatus === "PUBLIC" ? "private" : "public"
              }}</v-list-item-title
            >
          </v-list-item-content>

          <v-list-item-action>
            <v-btn
              icon
              color="deep-orange"
              @click="changeVisibility(tokenId, tokenStatus)"
              large
            >
              <v-icon>{{
                `mdi-eye${tokenStatus === "PUBLIC" ? "-off-" : "-"}outline`
              }}</v-icon>
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title
              >Set
              {{ isActiveTimeAuction ? "auction" : "token" }}
              position</v-list-item-title
            >
            <v-list-item-subtitle v-if="!allowHandsSettings"
              >To select the display order, select the manual setting on the
              homepage settings page
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-row>
              <v-col cols="9">
                <v-text-field
                  type="number"
                  v-model="position"
                  :placeholder="'Set position'"
                  :readonly="!allowHandsSettings"
                  color="'pink'"
                />
              </v-col>
              <v-col cols="2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      color="deep-orange"
                      v-bind="attrs"
                      large
                      :disabled="
                        (!allowHandsSettings || !position || position < -1) &&
                        position !== 0
                      "
                      v-on="on"
                      @click="
                        addMainPageData(
                          tokenId,
                          isActiveTimeAuction ? 'LIVE_AUCTION' : 'TOKEN'
                        )
                      "
                    >
                      <v-icon>mdi-check-all</v-icon>
                    </v-btn>
                  </template>
                  <span
                    >Save
                    {{ isActiveTimeAuction ? "auction" : "token" }}
                    position</span
                  >
                </v-tooltip>
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
import api from "../../api/api";
import contract from "../../api/contract";
import setProcessingMixin from "../../mixins/setProcessingMixin";
import updateMainPageDataMixin from "../../mixins/mainPage/updateMainPageDataMixin";

export default {
  name: "TokenSettingsPopover",
  mixins: [setProcessingMixin, updateMainPageDataMixin],
  props: {
    blockChainId: {
      type: Number,
      required: true,
    },
    tokenId: {
      type: String,
      required: true,
    },
    isActiveTimeAuction: {
      type: Boolean,
      required: true,
    },
    allowHandsSettings: {
      type: Boolean,
      required: true,
    },
    tokenStatus: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    menu: false,
  }),
  methods: {
    async changeVisibility(tokenId, tokenStatus) {
      try {
        const dialog = await this.$swal({
          title: `Do you want to make this token ${
            tokenStatus === "PUBLIC" ? "private" : "public"
          }?`,
          showDenyButton: false,
          showCancelButton: true,
          confirmButtonText: "Yes",
        });
        if (dialog.isConfirmed) {
          this.setLoading(true);
          await api.updateTokenVisible({
            tokenId: tokenId,
            status: tokenStatus !== "PUBLIC" ? "PUBLIC" : "PRIVATE",
          });
          this.$emit("visibilityCahnged", { id: tokenId, status: tokenStatus });
        }
      } catch (e) {
        this.setError(e.message);
      } finally {
        this.setLoading(false);
      }
    },
  },
};
</script>
