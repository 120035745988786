import api from "../../api/api";

export default {
    data: () => ({
        collectionsConfigs: [
            {
                text: "By hands",
                value: "BY_HANDS"
            },
            {
                text: "Last created",
                value: "LAST_CREATED"
            },
        ],
        creatorsConfigs: [
            {
                text: "By hands",
                value: "BY_HANDS"
            },
            {
                text: "Last registered",
                value: "LAST_REGISTERED"
            },
            {
                text: "More collections",
                value: "MORE_COLLECTIONS"
            },
            {
                text: "More tokens",
                value: "MORE_TOKENS"
            },
        ],
        liveAuctionsConfigs: [
            {
                text: "By hands",
                value: "BY_HANDS"
            },
            {
                text: "High price",
                value: "HIGH_PRICE"
            },
            {
                text: "Last created",
                value: "LAST_CREATED"
            },
            {
                text: "Low price",
                value: "LOW_PRICE"
            },
        ],
        nftTokensConfigs: [
            {
                text: "By hands",
                value: "BY_HANDS"
            },
            {
                text: "High price",
                value: "HIGH_PRICE"
            },
            {
                text: "Last created",
                value: "LAST_CREATED"
            },
            {
                text: "Low price",
                value: "LOW_PRICE"
            },
        ],

        currentCollectionsConfig: {},
        currentCreatorsConfig: {},
        currentLiveAuctionsConfig: {},
        currentNftTokensConfig: {},

        allowTokensHandsSettings: false,
        allowAuctionsHandsSettings: false,
        allowCreatorsHandsSettings: false,
        allowCollectionsHandsSettings: false,
    }),
    methods: {
        async getMainPageSettings() {
            try {
                const response = await api.getMainPageSettings();
                this.currentCreatorsConfig = this.creatorsConfigs.find(i => i.value === response.creators);
                this.currentCollectionsConfig = this.collectionsConfigs.find(i => i.value === response.collections);
                this.currentLiveAuctionsConfig = this.liveAuctionsConfigs.find(i => i.value === response.liveAuctions);
                this.currentNftTokensConfig = this.nftTokensConfigs.find(i => i.value === response.nftTokens);

                if (response.nftTokens === this.nftTokensConfigs[0].value) {
                    this.allowTokensHandsSettings = true;
                }
                if (response.liveAuctions === this.liveAuctionsConfigs[0].value) {
                    this.allowAuctionsHandsSettings = true;
                }
                if (response.creators === this.creatorsConfigs[0].value) {
                    this.allowCreatorsHandsSettings = true;
                }
                if (response.collections === this.collectionsConfigs[0].value) {
                    this.allowCollectionsHandsSettings = true;
                }
            } catch (e) {
                console.log(e);
            }
        },
    }
}
