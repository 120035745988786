import router from "@/router";

export default {
    namespaced: true,
    state: {
        isAuthorized: !!localStorage.getItem('accessToken'),
        token: localStorage.getItem('accessToken') || null
    },
    mutations: {
        setToken(state, payload) {
            state.isAuthorized = true;
            state.token = payload;
            localStorage.setItem('accessToken', payload);
        },
        unsetUser(state) {
            state.isAuthorized = false;
            state.token = null;
            localStorage.removeItem('accessToken');
        }
    },
    actions: {
        async logOut({ commit }) {
            commit("unsetUser");
            await router.push("/sign-in");
        },
    },
    getters: {
        getIsAuthorized: state => state.isAuthorized
    }
}
