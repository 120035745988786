import { mapActions, mapMutations } from "vuex";
import router from "@/router";

export default {
  methods: {
    ...mapActions({
      connectWallet: "wallet/connectWallet",
      logOut: "user/logOut",
    }),
    ...mapMutations({
      setIsEthereumDetected: "wallet/setIsEthereumDetected",
    }),
    checkEthereum() {
      const { ethereum } = window;
      return Boolean(ethereum && ethereum.isMetaMask);
    },
    checkConnectMethod(connectMethod) {
      return connectMethod || localStorage.getItem("connectionMethod");
    },
    async tryConnect(connectMethod) {
      try {
        this.setIsEthereumDetected(this.checkEthereum());
        await this.connectWallet(this.checkConnectMethod(connectMethod));
      } catch (e) {
        console.log(e);
        if (e.code === -32002 && router.currentRoute.name === "SignIn") {
          e.message = "Connect wallet request has been sent, check your wallet";
          throw e;
        }
      }
    },
  },
};
