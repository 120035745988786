<template>
  <div>
    <PriceRangeFilter
      @SubmitPrice="submitPrice"
      :from="filter.from"
      :to="filter.to"
    />
    <CurrencyFilter @ChangeCurrency="changeCurrency" :data="filter.currency" />
    <TockenStatusFilter @ChangeStatus="submitStatus" :data="filter.status" />
    <TockenTypeFilter @ChangeType="changeType" :data="filter.type" />
    <v-btn color="red" @click="filterReset">Reset filters</v-btn>
  </div>
</template>

<script>
import CurrencyFilter from "./CurrencyFilter.vue";
import TockenTypeFilter from "./TockenTypeFilter.vue";
import TockenStatusFilter from "./TockenStatusFilter.vue";
import PriceRangeFilter from "./PriceRangeFilter.vue";

import api from "@/api/api";
export default {
  name: "FilterComponent",
  props: ["allTokents", "searchInput"],
  emit: ["filter", "clear"],
  data: () => ({
    tokens: [],
    filter: {
      from: "",
      to: "",
      ready: false,
      currency: [],
      status: [],
      type: [],
    },
    search: "",
  }),
  watch: {
    searchInput(data) {
      this.search = data;
      this.filterAll();
    },
  },
  methods: {
    filterReset() {
      this.filter = {
        from: "",
        to: "",
        ready: false,
        currency: [],
        status: [],
        type: [],
      };
      this.$emit("clear");
    },
    async filterAll() {
      console.log(this.filter);
      try {
        console.log(this.search);
        this.loading = true;
        const response = await api.getPublicNftTokensByFilter({
          currencies: this.filter.currency,
          priceFrom:
            this.filter.to === "" && this.filter.ready
              ? undefined
              : this.filter.to,
          priceTo:
            this.filter.from === "" && this.filter.ready
              ? undefined
              : this.filter.from,
          search: this.search === "" ? undefined : this.search,
          statuses: this.filter.status,
          types: this.filter.type,
        });

        this.$emit("filter", {
          tokens: response.content,
          total: response.totalPages,
          totalElements: response.totalElements,
        });
        console.log(response.content);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    changeCurrency(currency) {
      this.filter.currency = currency;
      this.filterAll();
      console.log(this.filter.currency);
    },
    changeType(type) {
      this.filter.type = type;
      this.filterAll();
      console.log(this.filter.type);
    },
    submitPrice() {
      this.ready = true;
      this.filterAll();
      console.log(`${this.filter.from} ${this.filter.to}`);
      this.ready = false;
    },
    submitStatus(status) {
      this.filter.status = status;
      this.filterAll();
    },
  },
  components: {
    TockenTypeFilter,
    TockenStatusFilter,
    PriceRangeFilter,
    CurrencyFilter,
  },
};
</script>

<style></style>
