<template>
  <div>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Property</th>
            <th class="text-left">Value</th>
            <th class="text-left">Action</th>
          </tr>
        </thead>
        <tbody>
        <tr>
          <td>Admin pool</td>
          <td>
            <v-list style="background: transparent">
              <v-subheader>ADMINS</v-subheader>
              <v-list-item-group
                  color="pink"
              >
                <v-list-item
                    color="pink"
                    v-for="item in admins"
                    :key="item"
                >
                  <v-list-item-content>
                    <v-list-item-title v-text="item" />
                  </v-list-item-content>
                  <v-list-item-icon @click="_removeFromAdminPool(item)">
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </td>
          <td>
            <v-text-field type="text" v-model="address" placeholder="Enter address" />
            <v-btn @click="_addToAdminPool" color="pink" dark block class="mb-2 mt-2">Add to admin poll</v-btn>
          </td>
        </tr>
        <tr>
          <td>Rate step percent</td>
          <td>
            <v-text-field type="number" v-model="rateStepPercentVal"/>
          </td>
          <td>
            <v-btn @click="_setRateStepPercent" color="pink" dark block>Change value</v-btn>
          </td>
        </tr>
        <tr>
          <td>Min bid amount</td>
          <td>
            <v-text-field type="text" v-model="minBidAmount"/>
          </td>
          <td>
            <v-btn @click="_setMinBidAmount" color="pink" dark block>Set min bid amount</v-btn>
          </td>
        </tr>
        <tr>
          <td>Royalty percent</td>
          <td>
            <v-text-field type="number" v-model="royaltyPercent"/>
          </td>
          <td>
            <v-btn @click="_setMaxRoyaltyPercent" color="pink" dark block>Set royalty percent</v-btn>
          </td>
        </tr>
        <tr>
          <td>System fee percent</td>
          <td>
            <v-text-field type="number" v-model="systemFeePercent"/>
          </td>
          <td>
            <v-btn @click="_setSystemFeePercent" color="pink" dark block>Set system fee percent</v-btn>
          </td>
        </tr>
        <tr>
          <td>System address</td>
          <td>
            <v-text-field type="text" v-model="systemAddress"/>
          </td>
          <td>
            <v-btn @click="_setSystemAddress" color="pink" dark block>Set system address</v-btn>
          </td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import contract from "@/api/contract";
import setProcessingMixin from "@/mixins/setProcessingMixin";

export default {
  name: "Settings",
  mixins: [setProcessingMixin],
  data: () => ({
    rateStepPercentVal: 0,
    address: null,
    royaltyPercent: 0,
    minBidAmount: 0,
    systemFeePercent: 0,
    systemAddress: null,
    admins: [],
  }),
  methods: {
    async _setSystemAddress() {
      try {
        this.setLoading(true);
        await contract._setSystemAddress(this.systemAddress);
        this.setSuccess("System address has been set");
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    async _setSystemFeePercent() {
      try {
        this.setLoading(true);
        await contract._setSystemFeePercent(this.systemFeePercent);
        this.setSuccess("System fee percent has been changed");
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    async _setMaxRoyaltyPercent() {
      try {
        this.setLoading(true);
        await contract._setMaxRoyaltyPercent(this.royaltyPercent);
        this.setSuccess("Royalty percent has been changed");
      } catch (e) {
        this.setError(e.message || 'Unknown error occurred');
      } finally {
        this.setLoading(false);
      }
    },
    async _setMinBidAmount() {
      try {
        this.setLoading(true);
        await contract._setMinBidAmount(this.minBidAmount);
        this.setSuccess("Min bid amount has been changed");
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    async _addToAdminPool() {
      try {
        this.setLoading(true);
        await contract._addToAdminPool(this.address);
        this.setSuccess('Address has been added to admin pool');
        this.admins.push(this.address);
        this.address = "";
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    async getAdmins() {
      try {
        this.admins = await contract.getAdmins();
        this.admins = this.admins.filter(i => i !== "0x0000000000000000000000000000000000000000");
      } catch (e) {
        console.log(e.message || 'Unknown error occurred');
      }
    },
    async _removeFromAdminPool(address) {
      try {
        this.setLoading(true);
        await contract._removeFromAdminPool(address);
        this.setSuccess('Address has been removed from admin pool');
        this.admins = this.admins.filter(i => i !== address);
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },

    async _setRateStepPercent() {
      try {
        this.setLoading(true);
        await contract._setRateStepPercent(this.rateStepPercentVal);
        this.setSuccess("Step rate percent value has been changed");
      } catch (e) {
        this.setError(e.message || "Unknown error occurred");
      } finally {
        this.setLoading(false);
      }
    },
    async rateStepPercent() {
      try {
        this.rateStepPercentVal = await contract.rateStepPercent();
      } catch (e) {
        console.log(e.message || "Unknown error occurred");
      }
    },
    async maxRoyaltyPercent() {
      try {
        this.royaltyPercent = await contract.maxRoyaltyPercent();
      } catch (e) {
        console.log(e.message || "Unknown error occurred");
      }
    },
  },
  mounted() {
    this.rateStepPercent();
    this.maxRoyaltyPercent();
    this.getAdmins();
  }
}
</script>
