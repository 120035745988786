import Vue from 'vue'
import VueRouter from 'vue-router'
import routes from "./routes";
import NotAuthorizedGuard from './guards/NotAuthorizedGuard'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(NotAuthorizedGuard)

export default router
