var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main"},[_c('div',{staticClass:"main-filter"},[_c('FilterComponent',{attrs:{"allTokents":_vm.tokens,"searchInput":_vm.searchInput},on:{"clear":_vm.loadTokens,"filter":_vm.setFilteredTokens}})],1),_c('div',{staticClass:"main-table"},[_c('div',{staticClass:"main-table-header"},[_c('SearchInput',{attrs:{"placeholder":"Title"},on:{"clear":_vm.searchTockens,"search":_vm.searchTockens}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.tokens,"item-key":"name","loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","items-per-page":_vm.size},scopedSlots:_vm._u([{key:"item.fileUrl",fn:function({ item }){return [(item.type !== 'VIDEO' && item.type !== 'AUDIO')?_c('v-img',{staticStyle:{"border-radius":"50%","margin":"20px 0"},attrs:{"width":"50","height":"50","src":item.fileUrl}}):_vm._e(),(item.type === 'VIDEO')?_c('video',{staticStyle:{"border-radius":"50%","margin":"20px 0"},attrs:{"width":"50","height":"50","src":item.fileUrl}}):_vm._e()]}},{key:"item.selectToken",fn:function({ item }){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.selectToken(item.id)}}},[_vm._v("Select token")])]}},{key:"item.created",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("timePipe")(item.created))+" ")]}},{key:"item.status",fn:function({ item }){return [(item.status === 'NO_AUCTION')?_c('div',[_vm._v(" "+_vm._s(_vm._f("stringPipe")(item.activeAuction?.type))+" ")]):_c('div',[_vm._v(" "+_vm._s(_vm._f("stringPipe")(item.activeAuction.type))+" "),_c('OverlayContent',{attrs:{"selectedItem":item}})],1)]}},{key:"item.settings",fn:function({ item }){return [_c('TokenSettingsPopover',{attrs:{"token-id":item.id,"allow-hands-settings":(item.activeAuction &&
              item.activeAuction === 'TIME_AUCTION' &&
              _vm.allowAuctionsHandsSettings) ||
            (!item.activeAuction && _vm.allowTokensHandsSettings) ||
            (item.activeAuction &&
              item.activeAuction !== 'TIME_AUCTION' &&
              _vm.allowTokensHandsSettings),"is-active-time-auction":item.activeAuction && item.activeAuction === 'TIME_AUCTION',"tokenStatus":item.visibleStatus,"tokenId":item.id,"block-chain-id":item.blockChainId},on:{"visibilityCahnged":_vm.visibilityCahnged}})]}}])}),_c('div',{staticClass:"text-center pa-5"},[(_vm.total > 1)?_c('v-pagination',{attrs:{"length":_vm.total,"total-visible":_vm.size,"circle":"","color":"pink","prev-icon":"mdi-menu-left","next-icon":"mdi-menu-right"},on:{"input":_vm.loadTokens},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e()],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }