<template>
  <div class="page">
    Currency
    <FIterCheckbox
      v-for="(item, index) in types"
      :key="index"
      :value="item.value"
      :label="item.label"
      :data="data"
      @change="checkboxClick"
    />
  </div>
</template>

<script>
import FIterCheckbox from "./FIterCheckbox.vue";
export default {
  name: "TockenTypeFilter",
  props: ["data"],
  emit: ["ChangeCurrency"],
  data: () => ({
    types: [
      {
        label: "Eth",
        value: "ETH",
      },
      {
        label: "Matic",
        value: "MATIC",
      },
    ],
    chosenCurrency: [],
  }),
  methods: {
    checkboxClick(data) {
      if (data.add) this.chosenCurrency.push(data.value);
      else {
        this.chosenCurrency = this.chosenCurrency.filter(
          (i) => i !== data.value
        );
      }
      this.$emit("ChangeCurrency", this.chosenCurrency);
    },
  },
  components: {
    FIterCheckbox,
  },
};
</script>
