<template>
  <v-app app>
    <Preloader v-if="getLoading || loading"/>
    <div v-if="!loading">
      <router-view/>
    </div>
    <v-snackbar
        v-model="getError"
        elevation="24"
        color="pink"
        :timeout="snackbarTimeout"
    >
      {{getError}}

      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="setError(null)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
        v-model="getSuccess"
        elevation="24"
        color="green"
        :timeout="snackbarTimeout"
    >
      {{ getSuccess }}

      <template v-slot:action="{ attrs }">
        <v-btn
            dark
            text
            v-bind="attrs"
            @click="setSuccess(null)"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import {mapGetters} from "vuex";
import connectWalletMixin from "@/mixins/connectWalletMixin";
import setProcessingMixin from "@/mixins/setProcessingMixin";
import Preloader from "@/components/common/Preloader";

export default {
  name: 'App',
  components: {Preloader},
  data: () => ({
    loading: true,
    snackbarTimeout: 2000,
  }),
  mixins: [connectWalletMixin, setProcessingMixin],
  computed: {
    ...mapGetters({
      getSuccess: 'general/getSuccess',
      getLoading: 'general/getLoading',
      getError: 'general/getError',
      getIsAuthorized: 'user/getIsAuthorized',
      isWrongChainId: "wallet/isWrongChainId",
    })
  },
  watch: {
    getSuccess(val) {
      if (val) {
        setTimeout(() => {
          this.setSuccess(null);
        }, this.snackbarTimeout);
      }
    },
    getError(val) {
      if (val) {
        setTimeout(() => {
          this.setError(null);
        }, this.snackbarTimeout);
      }
    },
    isWrongChainId(val) {
      if (val) {
        this.setError(
            "You connected wrong network. Q NFT works with Ethereum and Polygon mainnet"
        );
      } else {
        this.setError(null);
      }
    },
  },
  async mounted() {
    if(this.getIsAuthorized) {
      this.loading = true;
      await this.tryConnect("metamask");
      this.loading = false;
    } else {
      this.loading = false;
    }
  }
};
</script>
