<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Select type</span>
        </v-card-title>
        <v-card-text>
          <v-container>
           <v-row v-if="!objectId">
             <v-col>
               <v-btn block large color="primary" :to="`/tokens?position=${position}`">Token</v-btn>
             </v-col>
             <v-col>
               <v-btn block large color="primary" :to="`/collections?position=${position}`">Collection</v-btn>
             </v-col>
           </v-row>
            <v-row v-else>
              <v-col>
                <v-btn block large color="error" @click="$emit('remove')">Remove object</v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    name: "SliderConfigSelectType",
    data: () => ({
      dialog: true
    }),
    props: {
      position: {
        type: Number,
        required: true,
      },
      objectId: {
        required: true
      }
    },
    methods: {
      close() {
        this.$emit("close")
      }
    }
  }
</script>