import http from "@/utils/http-common";

const authController = {
  AUTH: "/admin/auth",
  GET_SIGN_TOKEN: "/auth/sign-token",
};

const adminUserController = {
  GET_USERS: "/admin/get_users",
  CHANGE_ROLE: "/admin/change_role",
  SEARCH_USERS: "/admin/search_users",
};

const adminObjectController = {
  CREATE_ADMIN_OBJECT: "/settings/admin/main_content",
  DELETE_ADMIN_OBJECT: "/settings/admin/main_content",
  GET_ALL_ADMIN_OBJECTS: "/settings/main_page/main_content",
};

const reportsController = {
  GET_ALL: "/reports/get_all",
  ANSWER_REPORT: "/reports/answer_report",
};

const displayNftController = {
  NFT: "/nft",
  NFT_ALL: "/nft/all",
  SEARCH_BY_TITLE: "/nft/search",
  FILTER_NFT: "/nft/filter",
};

const displayCollectionController = {
  NFT_COLLECTION: "/nft_collection/admin",
  NFT_COLLECTION_SEARCH: "/nft_collection/search",
  NFT_COLLECTION_VISIBLE: "/nft_collection/visible",
};
const nftTokenController = {
  NFT_TOKEN_VISIBLE: "/admin/nft_token/visible",
};

const mainPageSettingsController = {
  GET_MAIN_PAGE_SETTINGS: "/settings/admin",
  UPDATE_MAIN_PAGE_DATA: "/settings/admin/data",
  DELETE_MAIN_PAGE_DATA: "/settings/admin/data",
  GET_CREATORS_BY_HANDS_SETTINGS:
    "/settings/admin/get_creators_by_hands_settings",
  GET_LIVE_AUCTIONS_BY_HANDS_SETTINGS:
    "/settings/admin/get_live_auctions_by_hands_settings",
  GET_NFT_COLLECTIONS_BY_HANDS_SETTINGS:
    "/settings/admin/get_nft_collections_by_hands_settings",
  GET_NFT_TOKENS_BY_HANDS_SETTINGS:
    "/settings/admin/get_nft_tokens_by_hands_settings",
};

export default {
  async changeCollectionVisible(payload) {
    const response = await http.put(
      displayCollectionController.NFT_COLLECTION_VISIBLE,
      null,
      {
        params: payload,
      }
    );
    return response.data;
  },

  async getAllNftTokens(payload) {
    const response = await http.get(displayNftController.NFT_ALL, {
      params: {
        ...payload,
      },
    });
    return response.data;
  },

  async updateTokenVisible(payload) {
    const response = await http.put(
      nftTokenController.NFT_TOKEN_VISIBLE,
      null,
      {
        params: payload,
      }
    );
    return response.data;
  },

  async searchNftCollections(payload) {
    const response = await http.get(
      displayCollectionController.NFT_COLLECTION_SEARCH,
      {
        params: payload,
      }
    );
    return response.data;
  },
  async getMainPageSettings() {
    const response = await http.get(
      mainPageSettingsController.GET_MAIN_PAGE_SETTINGS
    );
    return response.data;
  },

  async getCreatorsByHandsSettings(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_CREATORS_BY_HANDS_SETTINGS,
      { params: payload }
    );
    return response.data;
  },

  async getLiveAuctionsByHandsSettings(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_LIVE_AUCTIONS_BY_HANDS_SETTINGS,
      { params: payload }
    );
    return response.data;
  },

  async getNftCollectionsByHandsSettings(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_NFT_COLLECTIONS_BY_HANDS_SETTINGS,
      { params: payload }
    );
    return response.data;
  },

  async getNftTokensByHandsSettings(payload) {
    const response = await http.get(
      mainPageSettingsController.GET_NFT_TOKENS_BY_HANDS_SETTINGS,
      { params: payload }
    );
    return response.data;
  },

  async updateMainPageConfigs(payload) {
    const response = await http.put(
      mainPageSettingsController.GET_MAIN_PAGE_SETTINGS,
      payload
    );
    return response.data;
  },

  async addMainPageData(payload, type) {
    if (type === "put") {
      const response = await http.put(
        mainPageSettingsController.UPDATE_MAIN_PAGE_DATA,
        payload
      );
      return response.data;
    }

    const response = await http.post(
      mainPageSettingsController.UPDATE_MAIN_PAGE_DATA,
      payload
    );
    return response.data;
  },

  async updateMainPageData(payload) {
    const response = await http.put(
      mainPageSettingsController.UPDATE_MAIN_PAGE_DATA,
      payload.body,
      {
        params: payload.query,
      }
    );
    return response.data;
  },

  async deleteAdminMainContentRecord(payload) {
    const response = await http.delete(
      mainPageSettingsController.DELETE_MAIN_PAGE_DATA,
      {
        params: payload,
      }
    );
    return response.data;
  },

  async changeRole(payload) {
    const response = await http.get(adminUserController.CHANGE_ROLE, {
      params: payload,
    });
    return response.data;
  },
  async answerReport(payload) {
    return await http.post(reportsController.ANSWER_REPORT, payload);
  },
  async getUsers(payload) {
    const response = await http.get(adminUserController.GET_USERS, {
      params: payload,
    });
    return response.data;
  },
  async getAllReportRequests(payload) {
    const response = await http.get(reportsController.GET_ALL, {
      params: payload,
    });
    return response.data;
  },
  async getNftCollections(payload) {
    const response = await http.get(
      displayCollectionController.NFT_COLLECTION,
      {
        params: payload,
      }
    );
    return response.data;
  },

  async getPublicNftTokens(payload) {
    const response = await http.get(displayNftController.NFT, {
      params: {
        ...payload,
      },
    });
    return response.data;
  },

  async getPublicNftTokensByTitle(payload) {
    const response = await http.get(displayNftController.SEARCH_BY_TITLE, {
      params: {
        ...payload,
      },
    });
    return response.data;
  },

  async getPublicNftTokensByFilter(payload) {
    const response = await http.post(displayNftController.FILTER_NFT, payload);
    return response.data;
  },

  async createAdminObject(payload) {
    const response = await http.post(
      adminObjectController.CREATE_ADMIN_OBJECT,
      payload
    );
    return response.data;
  },

  async deleteAdminObject(payload) {
    const response = await http.delete(
      adminObjectController.DELETE_ADMIN_OBJECT,
      {
        params: payload,
      }
    );
    return response.data;
  },

  async getAllAdminObjects() {
    const response = await http.get(
      adminObjectController.GET_ALL_ADMIN_OBJECTS
    );
    return response.data;
  },

  async auth(payload) {
    const response = await http.post(authController.AUTH, payload);
    return response.data;
  },

  async getSignToken(payload) {
    const response = await http.post(authController.GET_SIGN_TOKEN, null, {
      params: payload,
    });
    return response.data;
  },
};
